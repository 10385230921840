import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Logo from "../images/logofinal.svg"

const defaultTransition = {
  exit: { length: 0, },
  entry: { delay: 0.1, },
}

const navLinks = [
  {
    pageName: 'Home',
    pageLink: '/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'About',
    pageLink: '/about/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Projects',
    pageLink: '/projects/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Services',
    pageLink: '/services/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Contact Us',
    pageLink: '/contact-us/',
    transitions: {...defaultTransition},
  },
];

const PageHeader = ({ siteTitle }) => {

  return (
    <header className="page-header">
      <div
        className="x-y-center"
        style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
      >
        <div
          className="flex-row y-center space-between container"
          style={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="flex-row y-center">
            <Link to="/" style={{ textDecoration: `none ` }}>
              <div className="page-header-logo-container">
                <Logo className="header-logo" />
              </div>
            </Link>
          </div>
          <nav
            className="desktop-nav"
            style={{ display: "flex", alignItems: "center", opacity: 1 }}
          >
            <ul className="desktop-nav-links">
              {
                navLinks.map((item, index) => {
                  return (
                  <li style={{ opacity: 1}}>
                    <Link 
                      to={item.pageLink} 
                      activeClassName="header-link-active"
                      style={{color: 'black'}}
                    >
                      {item.pageName}<span></span>
                    </Link>
                  </li>
                  )
                })
              }
            </ul>
            <ul className="desktop-nav-inquiry-block" style={{ opacity: 1, borderLeft: '1px solid rgba(0,0,0,0.6)' }}>
              <div className="desktop-nav-inquiry-text">Sales Inquiries</div>
              <a href="tel:4806307579" style={{color: 'rgba(0,0,0,0.8)'}}><div className="desktop-nav-inquiry-phone">+1 480 630 7579</div></a>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

PageHeader.propTypes = {
  siteTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  siteTitle: ``,
}

export default PageHeader
