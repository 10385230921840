import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import anime from "animejs/lib/anime.es.js"

import Logo from "../images/logofinalwhite.svg"

const defaultTransition = {
  exit: { length: 0, },
  entry: { delay: 0.1, },
}

const navLinks = [
  {
    pageName: 'Home',
    pageLink: '/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'About',
    pageLink: '/about/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Projects',
    pageLink: '/projects/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Services',
    pageLink: '/services/',
    transitions: {...defaultTransition},
  },
  {
    pageName: 'Contact Us',
    pageLink: '/contact-us/',
    transitions: {...defaultTransition},
  },
];

const Header = () => {
  const logoRef = useRef(useRef);
  const navRef = useRef(useRef);
  const finalPos = typeof window !== "undefined" ? [(window.innerWidth - 1170) / 2, window.innerWidth - (window.innerWidth - 1170) / 2] : [0,0];
  useEffect(() => {
    anime({
      targets: ".desktop-nav",
      opacity: [0, 1],
      duration: 300,
      easing: "easeInOutSine",
    })
    anime({
      targets: ".desktop-nav > ul > li",
      opacity: [0, 1],
      delay: (el, i) => i * 100,
      duration: 1000,
      easing: "easeInOutSine",
    })
    anime({
      targets: ".header-logo-container",
      opacity: [0, 1],
      translateY: [-5, 0],
      duration: 1000,
      easing: "easeInOutSine",
    })
    anime({
      targets: ".desktop-nav-inquiry-block",
      opacity: [0, 1],
      duration: 1000,
      delay: 600,
      easing: "easeInOutSine",
    })
  }, [])

  return (
    <header className="header">
      <div className="header-content">
        <div className="flex-row y-center">
          <Link to="/" style={{ textDecoration: `none` }}>
            <div className="header-logo-container" ref={logoRef}>
              <Logo className="header-logo" />
            </div>
          </Link>
        </div>
        <nav
          className="desktop-nav"
          ref={navRef}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ul className="desktop-nav-links">
            {
              navLinks.map((item, index) => {
                return (
                <li style={{ opacity: 1}}>
                  <Link 
                    to={`${item.pageLink}`} 
                    activeClassName="header-link-active"
                    style={{color: 'white'}}
                  >
                    {item.pageName}<span></span>
                  </Link>
                </li>
                )
              })
            }
          </ul>
          <ul className="desktop-nav-inquiry-block" style={{ color: 'white' }}>
            <div className="desktop-nav-inquiry-text">Sales Inquiries</div>
            <a href="tel:4806307579" style={{ color: 'white' }}><div className="desktop-nav-inquiry-phone">+1 480 630 7579</div></a>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
