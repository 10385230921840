import React from "react"
import { Link } from "gatsby";

import Logo from "../images/logofinalwhite.svg"

const Footer = () => {
  return (
    <footer className="footer x-center">
      <div className="container">
        <div className="flex-row" style={{ padding: "30px 0px 30px 0px" }}>
          <div style={{ flex: "1 0 65%" }}>
            <div>
              <h1>Renno Specialists</h1>
              <Link to="/contact-us" style={{ color: 'white' }}><h3 style={{ fontWeight: 400, lineHeight: 1.5 }}>
                Have a project? Contact us to learn what <br />
                we can do for you.
              </h3></Link>
            </div>
          </div>
          <div style={{ flex: "1 0 35%", display: "flex", flexWrap: "wrap" }}>
            <div style={{ flex: "1 0 50%" }}>
              <Link
                to="/"
                style={{
                  color: "white",
                  fontFamily: "Segoe UI",
                  fontWeight: 700,
                }}
              >
                Home
              </Link>
            </div>
            <div style={{ flex: "1 0 50%" }}>
              <Link
                to="/"
                style={{
                  color: "white",
                  fontFamily: "Segoe UI",
                  fontWeight: 700,
                }}
              >
                About
              </Link>
            </div>
            <div style={{ flex: "1 0 50%" }}>
              <Link
                to="/projects"
                style={{
                  color: "white",
                  fontFamily: "Segoe UI",
                  fontWeight: 700,
                }}
              >
                Projects
              </Link>
            </div>
            <div style={{ flex: "1 0 50%" }}>
              <Link
                to="/"
                style={{
                  color: "white",
                  fontFamily: "Segoe UI",
                  fontWeight: 700,
                }}
              >
                Services
              </Link>
            </div>
            <div style={{ flex: "1 0 50%" }}>
              <Link
                to="/"
                style={{
                  color: "white",
                  fontFamily: "Segoe UI",
                  fontWeight: 700,
                }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div
            className="header-logo-container"
            style={{ height: 180, width: 180, marginBottom: 16, opacity: 1 }}
          >
            <Logo style={{ padding: 12, height: 160, width: 160 }} />
          </div>
          <div style={{ fontFamily: "Segoe UI", fontSize: 14 }}>
            © 2020 Renno Specialists, LLC. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer