/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "typeface-krub";
import "typeface-roboto";
import "typeface-open-sans";

import Header from "./header"
import PageHeader from "./pageheader"
import Footer from "./footer"
import "./layout.css"
import "../sass/main.scss"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {location.pathname === '/' ?
        <Header siteTitle={data.site.siteMetadata.title} />
        :
        <PageHeader siteTitle={data.site.siteMetadata.title} />
      }
      
      
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
